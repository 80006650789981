/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

html {
  scroll-behavior: smooth;
}

:target::before {
  content: "";
  display: block;
  height: 100px;
  margin: -100px 0 0;
}

.pointer {
  cursor: pointer;
}

.heroContainer {
  -webkit-transition: background 10s linear;
  -moz-transition: background 10s linear;
  -o-transition: background 10s linear;
  -ms-transition: background 10s linear;
  transition: background 10s linear;
}
#rfs-btn {
  background-color: aliceblue;
  border-radius: 10px;
  width: 170px;
}
